@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.patient-details-page-content {

    &-assessments-header {
        width: 100%;

        & button {
            padding: 0.5rem 2.5rem;
            margin-left: 1rem;
        }

    }

    .assessment-card-container {
        width: 100%;

        &:last-of-type:not(:only-of-type) {
            margin: 0 0 4rem 0;
        }
    }

    .history-cards {
        margin: 0 0 1rem 0;
    }
    
    hr {
        width: 100%;
        margin: 2rem 0 1rem 0;
    }
    
    .react-tabs {
        -webkit-tap-highlight-color: transparent;
    
        &__tab-list {
            border-bottom: 1px solid $brand-gray;
            margin: 0 0 10px 0;
            padding: 0;
            width: $MAIN-CONTENT-WIDTH;
        }
    
        &__tab {
            background-color: white;
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 20px;
            cursor: pointer;
            transition: background-color 0.15s linear;
    
            &:hover {
                background-color: $brand-negative-space;
            }
    
            &--selected {
                background-color: white;
                border-bottom: 5px solid $brand-secondary;
                color: $brand-dark;
    
                &:hover {
                    background-color: white;
                }

                &:focus {
                    outline: none;
                }
            }
    
            &--disabled {
                color: $brand-gray;
                cursor: default;
            }
        }
    
        &__tab-panel {
            display: none;
    
            &--selected {
                display: block;
            }
        }
    }    
}

.select-assessments-popup {

  .popup-window {
    max-height: 80%;
    overflow-y: scroll;
    overscroll-behavior: none;
    width: 60%;

    .popup-window-content {

      .popup-window-header {
          margin-bottom: 5px;
      }

      .current-assessments-panel {
          min-height: 250px;
          width: 100%;

          label {
            .input-text {
              margin-bottom: 0px;
            }
            .labeled-text-input__instructions {
                font-style: italic;
                margin-bottom: 10px;
            }
          }
          
          .labeled-single-select {
              padding-bottom: 35px;
          }
      }

      .provider-assessment-card-wrapper {
        width: calc(33% - 1rem);
        min-width: 150px;
        margin: 0 1rem 1rem 0;
      }
    }
  }
}

.send-assessment-link-list {
    margin-bottom: 2rem;
}

.exit-tablet-mode-container {

  .popup-window-wrapper {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .popup-window-content {

      .popup-window-buttons {

        button:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.save-applied-when {
  button {
      margin-top: 35px;
  }
}
